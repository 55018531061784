import { Component, Prop, Vue } from 'vue-property-decorator'
import Container from 'typedi'
import SecurityContext from '@/modules/common/services/security-context.service'

@Component({
  name: 'GtrSubmoduleList'
})
export default class GtrSubmoduleList extends Vue {
  @Prop({ default: false, required: true })
  submodules?: Array<any>;

  @Prop({ required: true })
  miniVariant!: boolean

  get items () {
    return this.submodules
  }

  get routeIds () {
    return {
      uuid: this.$route.params.uuid,
      event_uuid: this.$route.params.event_uuid
    }
  }

  hasPermission (menuItem): boolean {
    const routePermissions = menuItem?.route?.meta?.permissions // string or array of strings
    const userGroupPermissions: any [] = Container.get(SecurityContext).permissions()
    let hasPermission = true

    if (routePermissions && routePermissions.length) {
      if (Array.isArray(routePermissions)) {
        hasPermission = routePermissions.some(permission => userGroupPermissions?.[permission]) // true if user has any of the required permissions
      } else if (typeof routePermissions === 'string') {
        hasPermission = userGroupPermissions?.[routePermissions] || false
      }
    }

    return hasPermission
  }

  setSubmoduleVisibility (event) {
    const module = event.toLowerCase()
    if (['registration', 'lead retrieval'].includes(module)) {
      this.$store.commit('module/SET_SUBMODULE_VISIBILITY', { routeName: 'level-two.event.payments', active: true })
    }
  }

  mounted () {
    this.$bus.$on('activate-module', this.setSubmoduleVisibility)
  }
}
